import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Split Jerk 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Weighted Strict Pullups 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`30-Push Press (135/95)`}</p>
    <p>{`1000M Row`}</p>
    <p>{`30-Jerks (135/95)`}</p>
    <p>{`For time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 5/5/16`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`On Saturday, October 28th The Ville will be hosting a fundraising
wod for the family of LMPD Officer Jason Schweitzer who was tragically
killed by a drunk driver in Lexington, Ky.  You can donate and purchase
a shirt at the link below.  CrossFit 222 will show up as the host but
you can choose your shirt size and our location when checking out.
 Please sign up soon and show your support for one of our LMPD officers
and his family!  There will also be a donation box on the day of the
wod.  We’ll only have classes at 9:00 and 10:00am at The Ville on this
day.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Event brite
link: `}<a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1"
          }}>{`https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1`}</a></em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.
Truegritcomp.wordpress.com.  We need 1 RX woman to fill a team! We also
need volunteers to judge and help with set up between wods.`}<strong parentName="p">{`**`}</strong>{`  We
need to order shirts today in order to have them on the day of the
competition. ***`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2"
      }}>{`https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      